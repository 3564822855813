import { Token } from '@archax/shared-types'
import { Typography, Table, TableHead, TableBody } from '@mui/material'
import { ReactElement, useCallback, useMemo } from 'react'
import { Wrapper, StyledTableCell, StyledTableRow } from './styles'
import { useTranslation } from 'react-i18next'

type Props = {
  operationData: Record<string, unknown>
  token: Token
}
export default function OperationDetailsModalContent({ operationData, token }: Props): ReactElement {
  const { t, i18n } = useTranslation()
  const formatterMap = useMemo(() => {
    return {
      protocol: (value: string) => t(`general.protocols.${value}` as any),
      standard: (value: string) => t(`general.standards.${value}` as any),
      tokenType: (value: string) => t(`general.tokenTypes.${value}` as any),
      numberToCreate: (value: string, operationData: any) => {
        const decimals = Number(operationData.decimals || 0)
        return new Intl.NumberFormat('en-US', { useGrouping: true, minimumFractionDigits: decimals }).format(
          Number(value) / Math.pow(10, decimals),
        )
      },
    } as Record<string, (value: any, operationData?: any) => string>
  }, [])
  const formatCellValue = useCallback((key: string, value: any) => {
    const formatter = formatterMap[key]
    if (formatter) {
      return formatter(value, operationData)
    }

    if (typeof value === 'object') {
      return JSON.stringify(value, null, 2)
    }
    if (typeof value === 'string') {
      return value
    }
    if (typeof value === 'number') {
      return value.toString()
    }
    if (typeof value === 'boolean') {
      return value ? t('general.yes') : t('general.no')
    }
    return 'N/A'
  }, [])

  const operationDataList = Object.keys(operationData)
    .map((key) => {
      if (![undefined, null, ''].includes(operationData[key] as any)) {
        return (
          <StyledTableRow key={key}>
            <StyledTableCell>
              {i18n.exists(`general.properties.${key}` as any) ? t(`general.properties.${key}` as any) : key}
            </StyledTableCell>
            <StyledTableCell flex={2} align="right">
              {formatCellValue(key, operationData[key])}
            </StyledTableCell>
          </StyledTableRow>
        )
      }
    })
    .filter((it) => !!it)

  const content =
    operationDataList.length > 0 ? (
      <>{operationDataList}</>
    ) : (
      <Typography variant="body2" color="textSecondary">
        {t('adminApproval.operationDetailsModal.noData')}
      </Typography>
    )

  return (
    <Wrapper>
      <Typography variant="h5" mb={4}>
        {t('adminApproval.operationDetailsModal.title')}
      </Typography>
      <Table>
        <TableHead>
          <StyledTableRow sx={{ display: 'flex', width: '100%' }}>
            <StyledTableCell flex={1}> {t('adminApproval.operationDetailsModal.keyLabel')}</StyledTableCell>
            <StyledTableCell flex={2} align="right">
              {' '}
              {t('adminApproval.operationDetailsModal.valueLabel')}
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody sx={{ maxHeight: 500, overflowY: 'scroll' }}>{content}</TableBody>
      </Table>
    </Wrapper>
  )
}
